import "../App.css";
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Outlet } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Fab from '@mui/material/Fab';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import FooterComponent from "../Components/Footer";
import header_logo from "../Assets/Images/Logo_normal.png"
import drawer_logo from "../Assets/Images/header_logo.png"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const drawerWidth = 240;

function Index(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <img src={drawer_logo} height={90} className="navbar-logo" alt=''></img>

            <Divider />
            <List>

                <ListItem>
                    <ListItemButton onClick={() => {
                        navigate("/");
                    }} sx={{ textAlign: 'center' }}>
                        INICIO
                    </ListItemButton>
                </ListItem>

                <ListItem>
                    <ListItemButton onClick={() => {
                        navigate("/nosotros");
                    }} sx={{ textAlign: 'center' }}>
                        NOSOTROS
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                        NUESTRAS POLÍTICAS
                    </ListItemButton>
                </ListItem>
                <Collapse in={true} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText>
                                <a href="https://politica-contruccaribeac.s3.us-east-2.amazonaws.com/POLI%CC%81TICA+AMBIENTAL+(1).pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                                    - POLÍTICA AMBIENTAL
                                </a>
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText>
                                <a href="https://politica-contruccaribeac.s3.us-east-2.amazonaws.com/POLI%CC%81TICA+DE+CALIDAD.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                                    - POLÍTICA DE CALIDAD
                                </a>
                            </ListItemText>
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText>
                                <a href="https://politica-contruccaribeac.s3.us-east-2.amazonaws.com/POLI%CC%81TICA+DE+SEGURIDAD+Y+SALUD+EN+EL+TRABAJO+(1).pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                                    - POLÍTICA DE SEGURIDAD Y SALUD
                                </a>
                            </ListItemText>
                        </ListItemButton>
                    </List>
                </Collapse>

                <ListItem>
                    <ListItemButton onClick={() => {
                        navigate("/trabaja");
                    }} sx={{ textAlign: 'center' }}>
                        TRABAJA CON NOSOTROS
                    </ListItemButton>
                </ListItem>

            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
                <Toolbar className="navBar" >
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <div sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} >
                        <img src={header_logo} className="navbar-logo" alt=''></img>
                    </div>

                    <Box sx={{ display: { xs: 'none', sm: 'block' }, height: "60%" }}>


                        <Button onClick={() => {
                            navigate("/");
                        }}
                            className="navButton" >
                            INICIO
                        </Button>

                        <Button onClick={() => {
                            navigate("/nosotros");
                        }} className="navButton" >

                            NOSOTROS
                        </Button>

                        <Button onClick={handleMenuClick} className="navButton">
                            NUESTRAS POLÍTICAS
                        </Button>
                        <Menu
                            id="policies-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem style={{ textDecoration: 'none' }}>
                                <a href="https://politica-contruccaribeac.s3.us-east-2.amazonaws.com/POLI%CC%81TICA+AMBIENTAL+(1).pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                                    POLÍTICA AMBIENTAL
                                </a>
                            </MenuItem>
                            <MenuItem style={{ textDecoration: 'none' }}>
                                <a href="https://politica-contruccaribeac.s3.us-east-2.amazonaws.com/POLI%CC%81TICA+DE+CALIDAD.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                                    POLÍTICA DE CALIDAD
                                </a>
                            </MenuItem>
                            <MenuItem style={{ textDecoration: 'none' }}>
                                <a href="https://politica-contruccaribeac.s3.us-east-2.amazonaws.com/POLI%CC%81TICA+DE+SEGURIDAD+Y+SALUD+EN+EL+TRABAJO+(1).pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
                                    POLÍTICA DE SEGURIDAD Y SALUD
                                </a>
                            </MenuItem>
                        </Menu>

                        <Button onClick={() => {
                            navigate("/trabaja");
                        }} className="navButton" >

                            TRABAJA CON NOSOTROS
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{ pt: 2, mb: 0 }}>
                <Toolbar />

                <Outlet></Outlet>
                <Fab href="https://wa.me/+573152927081" target="_blank" className="float_buttom" style={{ backgroundColor: "red", position: "fixed", bottom: "30px", right: "40px" }} aria-label="add">
                    <img width={80} src={require("../Assets/Images/whatsapp.png")} alt=''></img>
                </Fab>
                <FooterComponent></FooterComponent>

            </Box>



        </Box>
    );
}

Index.propTypes = {
    window: PropTypes.func,
};

export default Index;

