import React from 'react'
import "../Assets/Styles/AboutUs.css"

function AboutUs() {


    return (
        <div className="About_main">


            <div className='text_container ma-auto'>
                <h2> Acerca de Nosotros</h2>

                <p>
                    CONSTRUCCARIBE AC S.A.S es una empresa legalmente
                    constituida, dedicada y especializada a la ejecución de obras civiles, diseño,
                    remodelación, interventorías, mantenimientos preventivos, correctivos, sistemas
                    hidráulicos, eléctricos, estructurales, soluciones de recubrimientos en el sector
                    empresarial, industrial y residencial. Tenemos el privilegio de contar con más de 5
                    años de experiencia profesional, liderando importantes proyectos y obras, en el
                    campo de la ingeniería civil y arquitectura.
                </p>
            </div>

            <div className='text_container ma-auto'>
                <h2>¿Porqué Elegirnos?</h2>
                <p>
                    CONSTRUCCARIBE AC S.A.S  cumple estrictamente con todas las leyes y
                    disposiciones normativas vigentes del Estado Colombiano aplicables
                    al desarrollo de nuestras actividades, cumpliendo con responsabilidades
                    ambientales, tributarias, sistema de gestión de seguridad y salud en el
                    trabajo, lo que transmite a todos nuestros clientes total tranquilidad y
                    garantía de nuestros servicios.
                </p>
            </div>

            <div className='text_container ma-auto'>

                <h2>Nuestro Equipo</h2>
                <p>
                    CONSTRUCCARIBE AC S.A.S cuenta con un equipo interdisciplinario de profesionales,
                    técnicos y auxiliares, dispuestos a satisfacer las necesidades y expectativas de
                    nuestros clientes.
                </p>
            </div>



        </div>

    );
}

export default AboutUs;