import "../Assets/Styles/Map.css";
import { useState, useEffect } from "react";
// import echarts from 'echarts';
import json from "../Assets/geo.json";

import ReactECharts from "echarts-for-react";
const echarts = require("echarts");


const options = {

  title: {
    textStyle: {
      color: "#000"
    },
    subtextStyle: {
      color: "#000",
      fontSize: 16
    },
    left: "0"
  },
  tooltip: {
    show: true,
    trigger: "item",
    showDelay: 0,
    transitionDuration: 0.2,
    formatter: function (params) {
      return params.name;
    },
    textStyle: {
      color: "#000",
      fontSize: 16
    }
  },
  visualMap: {
    show: false,
    type: "piecewise",
    showLabel: true,
    dimension: 1,
    top: 0,
    backgroundColor: "#fff",
    left: "center",
    orient: "horizontal",
    categories: ["Category 1", "Category 2"],
    hoverLink: false,
    inRange: {
      color: {
        "Category 1": "transparent",
        "Category 2": "#F9D93A"
      }
    }
  },
  toolbox: {
    show: false,
    //orient: 'vertical',
    left: "left",
    top: "top",
    feature: {
      dataView: { readOnly: true },
      restore: {},
      saveAsImage: {}
    }
  },
  series: [
    {
      name: "Category 1",
      type: "map",
      roam: false,
      map: "COLOMBIA",
      emphasis: {
        disabled: false,
        label: {
          show: false
          // backgroundColor: "red"
        },
        itemStyle: {
          // color: "red",
          areaColor: "#eee",
          // opacity: 1,
          // borderWidth: 2
          shadowColor: "rgba(0, 0, 0, 0.5)",
          shadowBlur: 5
        }
      },
      select: {
        disabled: true
      },
      itemStyle: {
        areaColor: "#eee",
        color: "red",
        // shadowColor: "blue"
      },
      data: []

    }
  ]
};


echarts.registerMap("COLOMBIA", json, {

});

export default function Map() {
  const [dataOptions, setDataOptions] = useState(options);

  useEffect(() => {
    const fetchData = async () => {

      const resDepartamento = await fetch(
        'https://api-colombia.com/api/v1/Department'
      );

      const data = await resDepartamento.json();
      console.log(data)
      const newDatosElecciones = data.map((item) => {

        if (item?.name) {
          const name = item?.name.toUpperCase();
          let color = "";
          let nameCandidato = ''
          if (name === "BOLÍVAR" || name === "ATLÁNTICO" || name === "SUCRE" || name === "CÓRDOBA" | name === "LA GUAJIRA" || name === "CESAR" || name === "MAGDALENA") {
            nameCandidato = "Category 2";
            color = "#F9D93A";
          } else {
            nameCandidato = "Category 1";
            color = "#d5d4d3";
          }
          const result = {
            name,
            value: ["", nameCandidato],
            tooltip: {
              show: nameCandidato === "Category 1" ? true : true,
            },
            emphasis: {
              disabled: nameCandidato === "Category 1" ? true : false,
              itemStyle: {
                areaColor: color,
                borderWidth: 2
              }
            }
          };
          return result;
        }

        return {};
      });

      setDataOptions((prevOptions) => {
        const newOptiosn = {
          ...prevOptions,

          series: [{ ...prevOptions.series, data: newDatosElecciones }]
        };
        return newOptiosn;
      });
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <div className="Map">


      <ReactECharts

        option={dataOptions}
        style={{ width: "100%", height: "900px", objectFit: "cover", padding: "0px", marginBottom: "-70px", marginTop: "-50px" }}
      > </ReactECharts>
    </div>
  );
}
