import "../Assets/Styles/Header.css"



function FooterComponent() {
    return (
        <div className="Footer_main">

            <div className="row">

                <div className="left">
                    <img height={170} src={require("../Assets/Images/logo2.png")} alt=''></img>
                </div>

                <div className="footer">

                    <div className="list2">
                        <strong>Contáctanos</strong>
                        <span>Tel: (605) 3072178</span>
                        <span>Cel: 3006554195</span>
                        <span>gerencia@construccaribeac.com</span>
                    </div>
                </div>

            </div>

            <div className="end">
                <p>&copy; {new Date().getFullYear()} construccaribeac. Todos los derechos reservados.</p>
            </div>
        </div>

    );
}

export default FooterComponent;