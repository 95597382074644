import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from '../Pages';
import Main from '../Pages/Main';
import WorkUs from '../Pages/WorkUs';
import AboutUs from '../Pages/AboutUs';


function Router() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="" element={ <Index /> }  >
                    <Route index element={<Main />} />
                    <Route path="trabaja" element={<WorkUs />} />
                    <Route path="nosotros" element={<AboutUs />} />

                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Router;