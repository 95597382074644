import "../Assets/Styles/Header.css"

function Header() {
  return (
    <div className="Header_main">

      <div className="header">
        <h1> Diseñar, Construir, Transformar y Mantener como
          pilares fundamentales de nuestra Compañía.</h1>
        <h1 className="expertHands">¡Te encuentras en Manos expertas!</h1> {/* Agrega esta línea */}
      </div>

      <div className="subHeader">
        <h2>¡Entendemos tus necesidades!</h2>
        <a className="headerButton" href="https://wa.me/+573152927081" target="_blank" rel="noreferrer">Cotiza con nosotros</a>
      </div>
    </div>

  );
}

export default Header;