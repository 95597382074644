import * as React from 'react';
import AliceCarousel from 'react-alice-carousel';
import Header from "../Components/Header";
import "../Assets/Styles/Main.css";
import coomevaLogo from "../Assets/Images/GRUPO COOMEVA.png";
import exitoLogo from "../Assets/Images/GRUPO EXITO.png";
import avalLogo from "../Assets/Images/GrupoAval.png";
import baruLogo from "../Assets/Images/MARINA DE BARU.png";
import servibancaLogo from "../Assets/Images/servibanca.png";
import avVilla from "../Assets/Images/Av_Villas.png"
import bancoOccidente from "../Assets/Images/Banco_Occidente.png"
import bancoBogota from "../Assets/Images/Banco_de_Bogota.png"
import naturalPeople from "../Assets/Images/Natural_people.png"
import Map from '../Components/Map';

import service1 from '../Assets/Images/servicio-1.png'
import service2 from "../Assets/Images/servicio-2.png"
import service3 from "../Assets/Images/servicio-3.png"
import service4 from "../Assets/Images/servicio-4.png"
import service5 from "../Assets/Images/servicio-5.png"
import service6 from "../Assets/Images/servicio-6.png"
import service7 from "../Assets/Images/servicio-7.png"

import unity from "../Assets/Images/unity.png"
import integrity from "../Assets/Images/integrity.png"
import promise from "../Assets/Images/compromiso.png"
import idea from "../Assets/Images/innovacion.png"





function Main() {

    const handleDragStart = (e) => e.preventDefault();


    const responsive = {
        0: {
            items: 1,
            itemsFit: 'contain',
        },
        768: {
            items: 1
        },
        1024: {
            items: 2, itemsFit: 'contain',
        },
        1200: {
            items: 3, itemsFit: 'contain',
        }
    };

    const responsive2 = {
        0: {
            items: 1,
            itemsFit: 'contain',
        },
        600: {
            items: 2
        },
        950: {
            items: 2, itemsFit: 'contain',
        },
        1200: {
            items: 3, itemsFit: 'contain',
        }
    };

    const carouselItems = [
        <div className='item_card'>
            <div className="item_text"> DISEÑO Y EJECUCIÓN DE OBRAS CIVILES </div>
            <img className="desvanecer" alt='service' src={service1} />
        </div>,
        <div className='item_card'>
            <div className="item_text">MANTENIMIENTO PREVENTIVOS Y CORRECTIVOS LOCATIVOS Y ELÉCTRICOS</div>
            <img className="desvanecer" alt='service' src={service2} />
        </div>,
        <div className='item_card'>
            <div className="item_text">CONSULTORÍAS OBRAS CIVILES Y ELÉCTRICAS</div>
            <img className="desvanecer" alt='service' src={service3} />
        </div>,
        <div className='item_card'>
            <div className="item_text">ADECUACIONES CIVILES Y ELÉCTRICAS DE CAJEROS AUTOMÁTICOS</div>
            <img className="desvanecer" alt='service' src={service4} />
        </div>,
        <div className='item_card'>
            <div className="item_text">SUMINISTRO E INSTALACIÓN DE VIDRIOS TEMPLADOS, LAMINADOS Y TERMO ACÚSTICOS</div>
            <img className="desvanecer" alt='service' src={service5} />
        </div>,
        <div className='item_card'>
            <div className="item_text">SUMINISTRO Y APLICACIÓN DE RECUBRIMIENTO EPÓXICO</div>
            <img className="desvanecer" alt='service' src={service6} />
        </div>,
        <div className='item_card'>
            <div className="item_text">SISTEMA INTEGRAL DE AIRES ACONDICIONADOS</div>
            <img className="desvanecer" alt='service' src={service7} />
        </div>
    ];


    const items = [
        <img style={{ objectFit: "contain" }} height={75} src={coomevaLogo} onDragStart={handleDragStart} alt="presentation" />,
        <img height={75} src={bancoOccidente} onDragStart={handleDragStart} alt="presentation" />,
        <img height={75} src={bancoBogota} onDragStart={handleDragStart} alt="presentation" />,
        <img height={80} src={avVilla} onDragStart={handleDragStart} alt="presentation" />,
        <img height={110} src={avalLogo} onDragStart={handleDragStart} alt="presentation" />,
        <img height={100} src={servibancaLogo} onDragStart={handleDragStart} alt="presentation" />,
        <img height={100} src={exitoLogo} onDragStart={handleDragStart} alt="presentation" />,
        <img height={100} src={baruLogo} onDragStart={handleDragStart} alt="presentation" />,
        <img height={100} src={naturalPeople} onDragStart={handleDragStart} alt="presentation" />,

    ];

    return (
        <div className='main'>
            <Header></Header>
            <div className='section1'>
                <h1>Nuestros Servicios</h1>

                <div className='grid'>

                    <AliceCarousel dotsDisabled={true}
                        responsive={responsive}
                        autoPlay
                        infinite
                        autoPlayInterval={3000}
                        disableButtonsControls
                        buttonsDisabled={true}
                        items={carouselItems} />
                </div>

            </div>
            <div className='section2'>

                <h1>Nuestros Valores</h1>


                <div className='img_container'>
                    <div className='item_img'>
                        <img height={130} src={unity} alt='unity_img' />
                        <span>Unidad</span>
                    </div>

                    <div className='item_img'>
                        <img height={150} src={integrity} alt='integrity_img' />
                        <span>Integridad</span>
                    </div>

                    <div className='item_img'>
                        <img height={150} src={promise} alt='promise_img' />
                        <span>Compromiso</span>
                    </div>

                    <div className='item_img'>
                        <img height={150} src={idea} alt='idea_img' />
                        <span>Innovación</span>
                    </div>
                </div>




            </div>
            <div className='section3'>
                <div className='row'>
                    <div className='left'>
                        <h2>Cobertura en Colombia</h2>
                        <div className='list'>
                            Nuestros servicios se expanden por toda la región caribe,
                            estamos orgullosos de estar presente en los Departamentos de
                            Atlántico, Bolívar, César, Córdoba, Guajira, Magdalena y Sucre. Tú
                            solución está a un solo paso, sin importar en qué parte de esta región
                            te encuentres.
                        </div>
                    </div>
                    <div className='map'>
                        <Map></Map>

                    </div>


                </div>
                <div className="subHeader" style={{ textDecoration: 'none' }}>
                    <a className="" href="https://wa.me/+573152927081" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <h2>¡Solicita nuestro brochure y álbum de proyectos!</h2>
                    </a>
                </div>

            </div>

            <div className='section4'>
                <h1>Nuestros Clientes</h1>

                <div className='carousel_container'>
                    <AliceCarousel dotsDisabled={false}
                        responsive={responsive2}
                        disableButtonsControls
                        disableSlideInfo
                        buttonsDisabled={true} items={items} />
                </div>

            </div>


        </div>
    );
}

export default Main;

