import React from 'react'
import Button from '@mui/material/Button';
import "../Assets/Styles/WorkUs.css"

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



function WorkUs() {

    const [value, setValue] = React.useState(null);
    const [, setSelectedFile] = React.useState(null)




    //const fileInput = useRef(null)
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <div className="WorkUs_main">

            <h1> Trabaja con  Nosotros</h1>


            <div className='form-container'>
                <div className='row'>
                    <div className='column_form'>
                        <Box
                            className='form'
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '90%', display: 'flex', justifyContent: 'center', alignContent: 'center' },
                            }}
                            noValidate
                            autoComplete="off">
                            <span>
                                Tus capacidades profesionales, técnicas y auxiliares son importante para nosotros, has parte de nuestro equipo. <br></br>
                            </span>
                            <TextField size='small' label="Nombres" variant="outlined" />
                            <TextField size='small' label="Apellidos" variant="outlined" />
                            <TextField size='small' label="No. Documento" variant="outlined" />
                            <TextField size='small' label="Dirección" variant="outlined" />
                            <TextField size='small' label="Teléfono y/o Celular" variant="outlined" />
                            <TextField size='small' label="Email" variant="outlined" />
                            <LocalizationProvider size='small' dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    size='small'
                                    label="Fecha Nacimiento"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField size='small' margin="normal" fullWidth {...params} />}
                                />
                            </LocalizationProvider>

                            <h4>Adjunta tu CV (PDF): </h4>
                            <input className='inputFile' type="file" accept=".pdf" onChange={handleFileChange} />
                            <Button className="sendButton" variant="contained">Enviar</Button>
                        </Box>

                    </div>

                    <div className='column_img'>

                    </div>

                </div>


                <div className='contact'>
                    <Button variant="text">
                        <img width={45} src={require('../Assets/Images/gmail.png')} alt=''></img>
                    </Button>



                </div>


            </div>
        </div>

    );
}

export default WorkUs;